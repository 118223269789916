<template>
  <div class="tab-container">
    <overlay-loader :loading="loader"/>
    <hcc-input
      type="text"
      size="lg"
      placeholder="Search..."
      class="tab__search-input"
      name="Search"
      v-model="searchData"
    >
      <template #icon><magnify-icon /></template>
    </hcc-input>
    <div class="multimedia">
      <media-card
        v-for="item in multimedia"
        :key="item.id"
        :media="item"
        :mediaType="type"
        :deleteMedia="deleteMultimedia"
        :openModal="openPreview"
      >
        <div class="multimedia__item" @click="openPreview(item[type].url)">
          <slot name="item-preview" :url="buildUrl(item[type])"></slot>
        </div>
      </media-card>
      <div class="multimedia__drop">
        <drop-zone
          :fileType="fileTypes"
          :order="order"
          :type="type"
          :title="$t(`campaigns.data-bank.${[type]}.text`)"
          :theme="'grid'"
        />
      </div>
      <modal height="400" width="600" :name="`preview${type}`">
        <img
          class="multimedia__image"
          v-if="isImg"
          :src="url"
          alt="id-modalImg"
        />
        <video-player v-else :options="opt" />
      </modal>
      <hcc-confirmation />
    </div>
  </div>
</template>

<script>
import { getToken } from '@/vue-apollo';
import EventBus from '@/eventBus';
import { UPDATE_DATA_BANK } from '@/eventTypes';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';

export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    type: {
      type: String,
      required: true,
    },
    fileTypes: {
      type: String,
      required: true,
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    OverlayLoader,
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    DropZone: () => import('@/components/settings/DropZone.vue'),
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
    MediaCard: () => import('@/components/settings/dataBank/MediaComponents/MediaCard.vue'),
    VideoPlayer: () => import('@/components/settings/VideoPlayer.vue'),
  },
  data() {
    return {
      token: getToken(),
      searchData: '',
      url: null,
      opt: {
        autoplay: true,
        sources: [{
          src: '',
          type: 'video/mp4',
        }],
      },
    };
  },
  computed: {
    order() {
      return this.items.length;
    },
    isImg() {
      return this.type === 'image';
    },
    multimedia() {
      return this.items.filter(item => item[this.type].url != null)
        .filter((item) => {
          const name = item[this.type].filename.toLowerCase();
          const value = this.searchData.toLowerCase();
          return name.includes(value);
        });
    },
  },
  watch: {
    items() {
      this.opt = {
        autoplay: true,
        sources: [{
          src: '',
          type: 'video/mp4',
        }],
      };
      this.url = null;
    },
  },
  methods: {
    openPreview(multimediaItemUrl) {
      if (this.isImg) {
        this.url = `${multimediaItemUrl}?token=${this.token}`;
      } else {
        this.opt.sources[0].src = `${multimediaItemUrl}?token=${this.token}`;
      }
      this.$modal.show(`preview${this.type}`);
    },
    deleteMultimedia(props) {
      this.$modal.show('confirmation', {
        title: this.$t('common.delete'),
        description: this.$t('configuration.confirmation'),
        variant: 'error',
        confirm: () => {
          EventBus.$emit(UPDATE_DATA_BANK, {
            data: { id: props },
            remove: true,
            type: this.type,
          });
        },
      });
    },
    buildUrl(item) {
      return `${item.thumbnail}?token=${this.token}`;
    },
  },
};

</script>

<style scoped lang="scss">
@import "~styles/components/settings/dataBank/_multimedia-tab-databank.scss";
</style>
